import instance from "./index";

export function getAllCorporates() {
    return instance.get('/corporate/all')
}

export function getCorporateById(id) {
    return instance.get(`/corporate/${id}`)
}

export function disableCorporate(id,{
    disabled = false
}= {}) {
    return instance.put(`/corporate/${id}/disable`,{
        disable :disabled
    })
}

export function searchCorporate({ 
    page = 0, size = 20, 
    id, 
    name= '', 
    location='',
    emailAddress='', 
} = {}) {
    let params = {
        page: page,
        size: size,
        name: name,
        location: location,
        emailAddress: emailAddress,
    };
    if (id) {
        params.id = id;
    }
    return instance.get('/corporate/search', { params: params });
}