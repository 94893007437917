import React, { useEffect, useState } from 'react'
import { Table, Button, Form, Space, Input, Modal, Popconfirm, Select, notification, } from "antd";
import { Option } from 'antd/es/mentions';
import { FiSearch } from 'react-icons/fi';
import { useNavigate } from 'react-router-dom';
import { getAllUsers } from 'components/service/user.service';
import { disableUser } from 'components/service/user.service';
import { verifyUserIdentity } from 'components/service/user.service';
import styled from 'styled-components';
import { searchUser } from 'components/service/user.service';


const layout = {
    labelCol: {
        span: 6,
    },
    wrapperCol: {
        span: 19,
        offset: 1,
    },
};

const spanStyleEdit = {
    cursor: "pointer",
    textDecoration: "none",
    color: "#0074D9",
};

const HoverButton = styled(Button)`
    background-color: #008CBA;
    color: white;

    &:hover {
        background-color: #008CBA;
    }
`;

const User = () => {
    const [search, setSearch] = useState([]);
    const [form] = Form.useForm()
    const [isUpdateUserModalVisible, setIsUpdateUserModalVisible] = useState(false);
    const [isViewUserModalVisible, setIsViewUserModalVisible] = useState(false);
    const [isDisableUserModalVisible, setIsDisableUserModalVisible] = useState(false);
    const [selectedUser, setSelectedUser] = useState(null);
    const [users, setUsers] = useState([]);
    const navigate = useNavigate();

    const [tableCols] = useState([
        {
            key: "no",
            title: "No",
            dataIndex: "title",
            render: (text, post, index) => (
                <span
                    style={spanStyleEdit}
                    onClick={() => {
                        setSelectedUser(post);
                        setIsViewUserModalVisible(true);
                    }}
                >
                    {index + 1}. {post.title}
                </span>
            ),
        },
        { key: "name", title: "Name", render: (text, record) => `${record.firstName} ${record.lastName}`, },
        { key: "emailAddress", title: "Email", dataIndex: "emailAddress" },
        { key: "telephone", title: "Telephone", dataIndex: "telephone" },
        {
            key: "euserType",
            title: "User Type",
            dataIndex: "euserType",
            filters: [
                { text: 'Driver', value: 'DRIVER' },
                { text: 'Corporate', value: 'CORPORATE' },
            ],
            onFilter: (value, record) => record.euserType.indexOf(value) === 0,
        },
        {
            key: "disabled",
            title: "Disabled",
            dataIndex: "disabled",
            render: (disabled) => disabled ? "Yes" : "No"
        },
        {
            key: "role",
            title: "Role",
            dataIndex: "role", render: roles => roles[0].name,
            filters: [
                { text: 'Admin', value: 'ADMIN' },
                { text: 'User', value: 'USER' },
            ],
            onFilter: (value, record) => record.role[0].name.indexOf(value) === 0,
        },
        {
            key: "action", title: "Action", align: "center",
            render: (user) => [
                <Button
                    onClick={() => editPost(user)}
                    style={{ marginRight: "7px", color: "#0074D9" }}>
                    Verify
                </Button>,
                <Button
                    onClick={() => {
                        setSelectedUser(user);
                        setIsViewUserModalVisible(true);
                    }}
                    style={{ marginRight: "7px", color: "#0074D9" }}
                >
                    View
                </Button>,
                user && user.disabled ? (
                    <Button
                        style={{ backgroundColor: "#52c41a", color: "white" }}
                        onClick={() => {
                            confirmDisableOrEnableUser(user, false);
                        }}
                    >
                        Enable
                    </Button>
                ) : (
                    <HoverButton
                        onClick={() => {
                            confirmDisableOrEnableUser(user, true);
                        }}
                    >
                        Disable
                    </HoverButton>
                )
            ],
        },
    ]);

    const onUpdateUsertCancel = () => {
        setIsUpdateUserModalVisible(false);
    };

    const getUsers = () => {
        getAllUsers().then((response) => {
            setUsers(response.data);
        }).catch((error) => {
            console.log("Error: ", error)
        })
    }

    const editPost = (user) => {
        setSelectedUser(user);
        form?.setFieldsValue({
            firstName: user.firstName,
            lastName: user.lastName,
            emailAddress: user.emailAddress,
            identificationType: user.identificationType,
            idCardNumber: user.idCardNumber,
            telephone: user.telephone,
            disabled: user.disabled,
            verified: user.verified,
            idCardNumberVerified: user.idCardNumberVerified,
            euserType: user.euserType,
            phoneNumberVerified: user.phoneNumberVerified,
        });
        setIsUpdateUserModalVisible(true);
    };

    const confirmDisableOrEnableUser = (user, shouldDisable) => {
        Modal.confirm({
            title: shouldDisable ? 'Confirm Disable' : 'Confirm Enable',
            content: `Are you sure you want to ${shouldDisable ? 'disable' : 'enable'} this user?`,
            onOk() {
                handleDisableOrEnableUser(user, shouldDisable);
            },
        });
    };

    const handleUserIdentityVerification = () => {
        verifyUserIdentity(selectedUser.id, {
            EIdentificationType: selectedUser.identificationType,
            idCardNumber: selectedUser.idCardNumber,
            verified: selectedUser.verified,
        })
            .then((response) => {
                notification.success({
                    message: "User identity verification status updated successfully!",
                });
                getUsers();
            })
            .catch((error) => {
                notification.error({
                    message: "Failed to update user identity verification status!",
                });
            });

    }

    const handleDisableOrEnableUser = (user, shouldDisable) => {
        if (user) {
            disableUser(user.id, { disabled: shouldDisable })
                .then((response) => {
                    notification.success({
                        message: "User status updated successfully",
                    });
                    setIsDisableUserModalVisible(false);
                    getUsers();
                }).catch((error) => {
                    notification.error({
                        message: "Failed to update User status",
                    });
                })
        } else {
            console.error('user is null');
        }
    }

    const handleSearch = (searchParams) => {
        if (Object.values(searchParams).some(value => value.length > 0)) {
            searchUser(searchParams)
                .then((response) => {
                    if (response.data.length > 0) {
                        setSearch(response.data);
                        navigate('/admin/search-result', { state: { search: response.data } });
                    } else {
                        notification.error({
                            message: "No record found for the search value",
                        });
                        setSearch([]);
                    }
                }).catch((error) => {
                    console.log("Error: ", error)
                })
        } else {
            notification.error({
                message: "Please enter a search value",
            });
            setSearch([]);
        }
    };
    
    useEffect(() => {
        getUsers()
    }, []);

    return (
        <div style={{ marginTop: '5rem' }}>
            {/* modals edit */}
            <Modal
                open={isUpdateUserModalVisible}
                onCancel={onUpdateUsertCancel}
                footer={[
                    <Button
                        onClick={() => {
                            setIsUpdateUserModalVisible(false);
                        }}
                    >Cancel</Button>,
                    <Button
                        style={{ backgroundColor: "#2698FF", color: "white" }}
                        onClick={handleUserIdentityVerification}
                    >
                        Verify ID
                    </Button>,
                ]}
            >
                <label style={{ fontWeight: "bold", fontSize: '1.3rem' }}>User Identity</label>
                <Form
                    {...layout}
                    name="nest-messages"
                    form={form}
                    style={{ paddingTop: "35px" }}
                >
                    <Form.Item
                        label="Identification Type"
                        name={"identificationType"}
                        rules={[
                            {
                                required: true,
                            },
                        ]}
                    >
                        <Select
                            value={selectedUser?.identificationType}
                            onChange={(e) => {
                                setSelectedUser({
                                    ...selectedUser,
                                    identificationType: e,
                                });
                            }}
                        >
                            <Option value="OTERS_ID">OTERS ID</Option>
                            <Option value="NATIONAL_ID">NATIONAL ID</Option>
                            <Option value="DRIVERS LICENSE">DRIVERS LICENSE</Option>
                            <Option value="PASSPORT">PASSPORT</Option>
                            <Option value="BIRTH_CERTIFICATE">BIRTH CERTIFICATE</Option>
                        </Select>
                    </Form.Item>
                    <Form.Item
                        label="ID Card Number"
                        name={"idCardNumber"}
                        rules={[
                            {
                                required: true,
                            },
                        ]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        name={"verified"}
                        label="Verified"
                        rules={[
                            {
                                required: true,
                                message: "Please select the status of the vehicle!",
                            },
                        ]}
                    >
                        <Select
                            value={selectedUser?.verified.toString()}
                            onChange={(e) => {
                                setSelectedUser({
                                    ...selectedUser,
                                    verified: e === "true",
                                });
                            }}
                        >
                            <Option value="true">Yes</Option>
                            <Option value="false">No</Option>
                        </Select>
                    </Form.Item>
                </Form>
            </Modal>

            {/* modal view  */}
            <Modal
                open={isViewUserModalVisible}
                onCancel={() => {
                    setIsViewUserModalVisible(false);
                }}
                footer={[
                    <Button
                        onClick={() => {
                            setIsViewUserModalVisible(false);
                        }}
                    >
                        Cancel
                    </Button>,
                    <Button
                        style={{ backgroundColor: "#2698FF", color: "white" }}
                        onClick={() => {
                            setIsViewUserModalVisible(false);
                            editPost(selectedUser);
                        }}
                    >
                        Edit{" "}
                    </Button>,
                ]}
            >
                <Form
                    {...layout}
                    name="nest-messages"
                    form={form}
                    style={{ paddingTop: "35px" }}
                >
                    <Form.Item name={"firstName"} label="First Name">
                        <p style={{ margin: 0 }}>{selectedUser?.firstName}</p>
                    </Form.Item>
                    <Form.Item name={"lastName"} label="Last Name">
                        <p style={{ margin: 0 }}>{selectedUser?.lastName}</p>
                    </Form.Item>
                    <Form.Item name={"emailAddress"} label="Email">
                        <p style={{ margin: 0 }}>{selectedUser?.emailAddress}</p>
                    </Form.Item>
                    <Form.Item name={"identificationType"} label="Identification Type">
                        <p style={{ margin: 0 }}>{selectedUser?.identificationType}</p>
                    </Form.Item>
                    <Form.Item name={"idCardNumber"} label="ID Card Number">
                        <p style={{ margin: 0 }}>{selectedUser?.idCardNumber}</p>
                    </Form.Item>
                    <Form.Item name={"disabled"} label="Disabled">
                        <p style={{ margin: 0 }}>{String(selectedUser?.disabled)}</p>
                    </Form.Item>
                    <Form.Item name={"telephone"} label="Telephone">
                        <p style={{ margin: 0 }}>{selectedUser?.telephone}</p>
                    </Form.Item>
                    <Form.Item name={"euserType"} label="User Type">
                        <p style={{ margin: 0 }}>{selectedUser?.euserType}</p>
                    </Form.Item>
                    <Form.Item name={"idCardNumberVerified"} label="Verified ID Card ">
                        <p style={{ margin: 0 }}>{String(selectedUser?.idCardNumberVerified)}</p>
                    </Form.Item>
                    <Form.Item name={"phoneNumberVerified"} label="Verified Phone">
                        <p style={{ margin: 0 }}>{String(selectedUser?.phoneNumberVerified)}</p>
                    </Form.Item>
                </Form>
            </Modal>

            {/* main content */}
            <div style={{ display: 'flex', flexDirection: 'row', marginBottom: '1.5rem' }}>
                <div className="relative mt-[3px] flex h-[45px] w-[355px] flex-grow items-center justify-around gap-2 rounded-full bg-white px-2 py-2 shadow-xl shadow-shadow-500 dark:!bg-navy-800 dark:shadow-none md:w-[200px] md:flex-grow-0 md:gap-1 xl:w-[250px] xl:gap-2">
                    <div className="flex h-full items-center rounded-full bg-white  text-navy-700 dark:bg-navy-900 dark:text-white xl:w-[225px]">
                        <p className="pl-3 pr-2 text-xl">
                            <FiSearch className="h-4 w-4 text-gray-400 dark:text-white" />
                        </p>
                        <input
                            type="text"
                            placeholder="Search..."
                            className="block h-full w-full rounded-full bg-white  text-sm font-medium text-navy-700 outline-none placeholder:!text-gray-400 dark:bg-navy-900 dark:text-white dark:placeholder:!text-white sm:w-fit"
                            onKeyPress={(e) => {
                                if (e.key === 'Enter') {
                                    handleSearch(e.target.value);
                                }
                            }}
                        />
                    </div>
                </div>
                {/* <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', marginBottom: '1rem' }}>
                    <button style={{ width: '8rem', }} className="linear mt-2 rounded-xl bg-brand-500 py-[8px] text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200"
                        onClick={() => {
                            setIsUpdateUserModalVisible(true);
                        }}
                    >
                        Add User
                    </button>
                </div> */}
            </div>
            <Table columns={tableCols} dataSource={search?.length > 0 ? search : users} />
        </div >
    )
}


export default User